import React from 'react'

const Contactus = () => {
  return (
   <>
   <h1>Contact Us</h1>
   </>
  )
}

export default Contactus