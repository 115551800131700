export const GET_ALL_PRODUCTS_SUCCESS = "GET_ALL_PRODUCTS_SUCCESS";
export const GET_ALL_PRODUCTS_FAIL = "GET_ALL_PRODUCTS_FAIL";

export const GET_CART_PRODUCTS_SUCCESS = "GET_CART_PRODUCTS";
export const GET_CART_PRODUCTS_FAIL = "GET_CART_PRODUCTS_FAIL";


export const GET_ALL_CATEGORIES_SUCCESS = "GET_ALL_CATEGORIES_SUCCESS";
export const GET_ALL_CATEGORIES_FAIL = "GET_ALL_CATEGORIES_FAIL";


export const ADD_TO_CART_SUCCESS = "ADD_TO_CART_SUCCESS"
export const ADD_TO_CART_FAIL = "ADD_TO_CART_FAIL"
export const REMOVE_FROM_CART = "REMOVE_FROM_CART"
export const CLEAN_CART = "CLEAN_CART"